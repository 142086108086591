import { Block, Error, Layout, Seo, StickyLinks } from "@components"

import React from "react"
import { graphql } from "gatsby"

const Page = ({ data: { page } }) => {
  return (
    <Layout
      scrollSnapping={page?.pageFields?.scrollSnapping}
      pageModuleCount={page?.pageModules?.pageModules?.length}
    >
      <Seo
        {...{
          description: page?.seo?.metaDesc,
          meta: page?.seo?.metaDesc,
          title: page?.seo?.title,
        }}
      />

      <StickyLinks
        prev={page?.stickyLinks?.previousLink}
        next={page?.stickyLinks?.nextLink}
      />

      <main>
        {page?.pageModules ? (
          page?.pageModules?.pageModules?.map((block, index) => {
            return <Block key={index} blockData={block} />
          })
        ) : (
          <Error message="Hmm - there is no content for this page." />
        )}
      </main>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query PageById($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      title

      stickyLinks {
        nextLink {
          target
          title
          url
        }
        previousLink {
          target
          title
          url
        }
      }

      seo {
        metaDesc
        title
      }

      pageModules {
        pageModules {
          ... on WpPage_Pagemodules_PageModules_Hero {
            background
            blockId
            fieldGroupName
            heroPageTitle
            heroTitlePosition
            heroDesktop
            heroMobile
            addHeroImages {
              heroOverlay
              heroImage {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 2400
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }
          }

          ... on WpPage_Pagemodules_PageModules_TextBlock {
            background
            blockId
            fieldGroupName
            paddingBottom
            paddingTop
            textBlockText
          }

          ... on WpPage_Pagemodules_PageModules_EmployeesGrid {
            background
            blockId
            employees {
              ... on WpEmployee {
                id
                title
                slug
                content
                employeeFields {
                  employeeTitle
                }
                featuredImage {
                  node {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          width: 800
                          placeholder: BLURRED
                          formats: [AUTO, WEBP]
                        )
                      }
                    }
                  }
                }
              }
            }
            fieldGroupName
            paddingBottom
            paddingTop
          }

          ... on WpPage_Pagemodules_PageModules_ProjectsGrid {
            background
            blockId
            fieldGroupName
            paddingBottom
            paddingTop
            projects {
              ... on WpProject {
                slug
                uri
                title
                categories {
                  nodes {
                    name
                    databaseId
                  }
                }
                featuredImage {
                  node {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          width: 800
                          placeholder: BLURRED
                          formats: [AUTO, WEBP]
                        )
                      }
                    }
                  }
                }
              }
            }
          }

          ... on WpPage_Pagemodules_PageModules_Services {
            background
            blockId
            fieldGroupName
            paddingBottom
            paddingTop
            services {
              ... on WpService {
                content
                title
                slug
              }
            }
          }

          ... on WpPage_Pagemodules_PageModules_NewsArticles {
            background
            blockId
            fieldGroupName
            paddingTop
            paddingBottom
          }

          ... on WpPage_Pagemodules_PageModules_Vacancies {
            background
            blockId
            fieldGroupName
            paddingTop
            paddingBottom
          }

          ... on WpPage_Pagemodules_PageModules_CallToAction {
            background
            blockId
            fieldGroupName
            actionLink {
              target
              title
              url
            }
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 2400
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
            subTitle
            textPosition
            title
          }
        }
      }
    }
  }
`
